import MarkdownText from '@components/MarkdownText';
import { Grid, H3 } from '#ui';
import { getSpacing } from '@lib/utils';
import AppButton from '@components/AppButton';
import type { FC } from 'react';
import type { LargeTextSectionProps as Props } from './types';

const LargeTextSection: FC<Props> = ({ mdCopy, order, cta, simpleIcon }) => {
  const ctaPresent = cta?.destination;
  //stripping any # so that heading styling does not come through
  /**
   * Also, editors should not be able to add line breaks.
   * 1. The design does not call for it.
   * 2. Allowing line breaks will always force render one or multiple paragraphs
   *    which would violate html specs in a lot of cases.
   * 3. <br> should never be used for presentation purposes.
   */
  const result: string = (mdCopy as string)
    .replace(/#+\s/g, '')
    .replace(/\n/g, ' ')
    .replace(/<br>/g, ' ');

  return (
    <Grid
      as="section"
      sx={{
        marginBlockStart: getSpacing(order),
        textAlign: ['left', 'center'],
        paddingBlockStart: [3, 5],
        paddingBlockEnd: [3, 5],
      }}
    >
      {simpleIcon?.url && (
        <img
          sx={{
            size: '4rem',
            gridColumn: ['1/2', '2/3'],
            marginInlineStart: ['0px', 0],
            marginBlockStart: 2,
          }}
          src={simpleIcon.url}
          alt={simpleIcon.description || ''}
          loading="lazy"
        />
      )}
      <H3
        sx={{
          gridColumn: ['1/-1', '3/-3', null],
          maxWidth: 10,
          margin: 'auto',
          '& a': !ctaPresent && {
            textDecorationThickness: '3px',
            textUnderlineOffset: '.3em',
            whiteSpace: 'break-spaces',
          },
        }}
      >
        <MarkdownText
          styles={{
            variant: 'styles.h3',
            fontWeight: ctaPresent && ['extraBold', 'extraBold'],
          }}
          linkVariant="Heading"
        >
          {result}
        </MarkdownText>
      </H3>
      {ctaPresent && (
        <AppButton
          {...cta}
          componentStyle={{
            marginBlockStart: 5,
            justifySelf: ['stretch', 'center'],
          }}
          color="Violet"
        >
          {cta.ctaCopy}
        </AppButton>
      )}
    </Grid>
  );
};

export default LargeTextSection;
